import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePrevious } from 'react-use';
import { TourSearch } from './TourSearch';
import { Page } from '@model/common';
import { performNavigate, unsetPackageReferences } from '@state/app';
import { fetchToursByType } from '@state/algolia/algoliaOperations';
import { getSearchParams, getToursByType } from '@state/search/tours/tourSearchSelectors';
import { unsetLeadInTourReferences, unsetTourReferences, unsetTourFilters } from '@state/tours';
import { resetPaymentForm } from '@state/payment/paymentFormOperations';
import { Keyable } from '@model/common';
import { TripTypes } from '@model/common/tours/trip-types';
import { getToursSearchUrlParams } from '@util/tours';

export interface TourSearchContainerProps {
  condensed?: boolean;
  isVisible: boolean;
  tripType: TripTypes;
}

export const TourSearchContainer = ({ condensed = false, isVisible, tripType }: TourSearchContainerProps) => {
  const searchParams = useSelector(getSearchParams);
  const tours = useSelector(getToursByType(tripType));
  const dispatch = useDispatch();
  const prevVisible = usePrevious(isVisible);

  useEffect(() => {
    if (isVisible && prevVisible === false) {
      dispatch(fetchToursByType(tripType));
    }
  }, [isVisible]);

  const handleOnClick = () => {
    const query = getToursSearchUrlParams({ tripType, tours, searchParams }) as Keyable;
    dispatch(unsetLeadInTourReferences());
    dispatch(unsetTourReferences());
    dispatch(unsetPackageReferences());
    dispatch(unsetTourFilters());
    dispatch(resetPaymentForm());
    dispatch(
      performNavigate({
        pathname: Page.TOURS_SEARCH,
        query
      })
    );
  };

  return <TourSearch tripType={tripType} isCondensed={condensed} isVisible={isVisible} onClick={handleOnClick} />;
};
