import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps } from '@theme/base';
import { useI18NextContext } from '@components/hooks';
import { FormControlLabel } from '@mui/material';
import { Checkbox as MUICheckbox } from '@styles/material-ui';
import { Localisation } from '@model/common';
import { getLabelText } from '@util/common';

/* ***************** *
 *       Types       *
 * ***************** */
export interface CheckboxProps {
  theme?: Theme;
  testId?: string;
  value: any;
  checkboxStyles?: any;
  checked?: boolean;
  label?: string | Localisation;
  labelComponent?: JSX.Element;
  id?: string;
  onChange: (CheckboxResponse: CheckboxResponse) => void;
  available?: boolean;
  className?: string;
}

export interface CheckboxResponse {
  value: string;
  checked: boolean;
  name: string;
}

/* ***************** *
 *       Styles      *
 * ***************** */
interface ContainerProps extends ThemeProps {
  available?: boolean;
  checked?: boolean;
}

const Container: any = styled.div(({ theme, available, checked }: ContainerProps) => ({
  width: '100%',

  ['.MuiFormControlLabel-root']: {
    alignItems: 'center'
  },
  ...(!available && {
    ['.MuiFormControlLabel-label']: {
      color: theme.custom.colors.group10.dark
    }
  }),
  ...(checked &&
    !available && {
      ['.MuiFormControlLabel-label']: {
        color: theme.custom.colors.group10.dark
      }
    })
}));

interface CheckboxLabelProps extends ThemeProps {
  isChecked: boolean;
}

export const CheckboxLabel: any = styled.span(({ theme }: CheckboxLabelProps) => ({
  display: 'inline-block',
  ...(theme.custom.typography.paragraph as any),
  transition: `color ${theme.custom.transitions.default}`
}));

/* *********************** *
 *    CheckboxComponent    *
 * *********************** */

export const CheckboxComponent = (props: CheckboxProps) => {
  const { value, checkboxStyles, label, onChange, checked, labelComponent, testId, id, available, className } = props;
  const t: any = useI18NextContext();

  /* *** LOGIC *** */
  const handleOnToggle = (value: any, event: any) => {
    if (typeof checked === 'undefined') {
      setIsChecked(!isChecked);
    }

    onChange({ value, checked: event.target.checked, name: event.target.id });
  };

  /* *** STATE *** */
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(checked || false);

  /* *** HOOKS *** */
  useEffect(() => {
    setIsChecked(checked || false);
  }, [checked]);

  return (
    <Container data-testid={TestId.checkbox.main} available={available} checked={checked} className={className}>
      <FormControlLabel
        data-testid={testId || TestId.checkbox.formControl}
        onMouseOver={() => setIsHover(true)}
        onMouseOut={() => setIsHover(false)}
        control={
          <MUICheckbox
            testId={TestId.review.specialOffers.upgradeCheckbox}
            value={value}
            onToggle={handleOnToggle}
            label={value}
            checked={isChecked}
            hover={isHover}
            checkboxStyles={checkboxStyles}
            available={!!available}
            id={id}
          />
        }
        label={
          labelComponent ||
          (label && (
            <CheckboxLabel data-testid={TestId.checkbox.label} isChecked={isChecked}>
              {getLabelText(label, t)}
            </CheckboxLabel>
          ))
        }
      />
    </Container>
  );
};

export const Checkbox = withTheme(CheckboxComponent);

Checkbox.defaultProps = {
  available: true
};
