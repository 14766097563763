import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TestId } from '@components/test-ids';
import { Grid } from '@mui/material';
import { DestinationsContainer } from '@components/search/destinations/DestinationsContainer';
import { AirportsContainer } from '@components/search/airports/AirportsContainer';
import { DatesContainer } from '@components/search/dates/DatesContainer';
import { DurationContainer } from '@components/search/duration/DurationContainer';
import { OccupancyContainer } from '@components/search/occupancy/OccupancyContainer';
import { SearchButton } from '@components/search/tabs/common/search-button';
import { MaxContentWidth } from '@components/common/layout';
import { DealFinderErrors, DealFinderState } from '@model/state';
import { getDealFinderState, validateDealFinderState } from '@state/deal-finder/dealFinderSelectors';
import { displayErrors, setSearchType, setHotelLandingSearch } from '@state/deal-finder/dealFinderOperations';
import { unsetLeadInTourReferences, unsetTourReferences } from '@state/tours/tour-references/tourReferencesOperations';
import { resetPaymentForm } from '@state/payment/paymentFormOperations';
import { SearchBarContext } from '@components/search/tabs/common/searchBarContext';
import { SearchType } from '@model/iceberg/deal-finder/deal-finder';
import { performNavigate, unsetPackageReferences } from '@state/app';
import { Page } from '@model/common';
import { getSearchQuery } from '@util/deal-finder';

export interface DealFinderSearchContainerProps {
  condensed: boolean;
  onSearch?: () => void;
  isVisible?: boolean;
}

export const DealFinderSearchContainer = ({ condensed, onSearch, isVisible }: DealFinderSearchContainerProps) => {
  const ref: any = useRef();
  const dispatch = useDispatch();
  const dealFinderState: DealFinderState = useSelector(getDealFinderState);
  const dealFinderErrors: DealFinderErrors = dealFinderState.errors;
  const handleOnSubmit = () => {
    const dealFinderFormValid: boolean = validateDealFinderState({
      ...dealFinderState,
      searchType: SearchType.MAIN
    });
    dispatch(setHotelLandingSearch(false));
    const query = {
      ...getSearchQuery({
        ...dealFinderState,
        searchType: SearchType.MAIN
      })
    };

    if (dealFinderFormValid) {
      dispatch(unsetPackageReferences());
      dispatch(unsetLeadInTourReferences());
      dispatch(unsetTourReferences());
      dispatch(resetPaymentForm());
      dispatch(setSearchType(SearchType.MAIN));
      dispatch(performNavigate({ pathname: Page.SEARCH, query }));
    } else {
      dispatch(displayErrors());
    }
    if (onSearch) {
      onSearch();
    }
  };
  return (
    <SearchBarContext.Provider value={{ condensed }}>
      <MaxContentWidth testId={TestId.searchContainer}>
        <Grid container={true} spacing={2} ref={ref}>
          <Grid item={true} xs={12} sm={6} lg={3}>
            <DestinationsContainer tabsRef={ref} isCondensed={condensed} isError={dealFinderErrors.destinations} />
          </Grid>
          <Grid item={true} xs={12} sm={6} lg={2}>
            <AirportsContainer
              tabsRef={ref}
              isCondensed={condensed}
              isError={dealFinderErrors.airports}
              isVisible={isVisible}
            />
          </Grid>
          <Grid item={true} xs={12} sm={6} lg={2}>
            <DatesContainer tabsRef={ref} isCondensed={condensed} isError={dealFinderErrors.dates} />
          </Grid>
          <Grid item={true} xs={12} sm={6} lg={2}>
            <DurationContainer tabsRef={ref} isCondensed={condensed} />
          </Grid>
          <Grid item={true} xs={12} sm={6} lg={2}>
            <OccupancyContainer tabsRef={ref} isCondensed={condensed} isError={dealFinderErrors.occupancy} />
          </Grid>
          <Grid item={true} xs={12} sm={6} lg={1}>
            <SearchButton onClick={handleOnSubmit} condensed={condensed} />
          </Grid>
        </Grid>
      </MaxContentWidth>
    </SearchBarContext.Provider>
  );
};

DealFinderSearchContainer.defaultProps = {
  condensed: false
};
