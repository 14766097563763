import React from 'react';
import { TourDestinations } from './TourDestinations';
import { TourHit } from '@model/search/alogolia/tour-hit';
import { useDispatch, useSelector } from 'react-redux';
import { TripTypes } from '@model/common/tours/trip-types';
import { getAlgoliaToursByType } from '@state/algolia/algoliaSelectors';
import { setToursQueryByType } from '@state/algolia/algoliaOperations';
import { getSearchParams, getToursByType } from '@state/search/tours/tourSearchSelectors';

export interface DestinationsContainerProps {
  tabsRef: any;
  onAdd: (tour: TourHit) => void;
  onRemove: (tour: TourHit) => void;
  onClear: () => void;
  isCondensed: boolean;
  tripType: TripTypes;
  isVisible: boolean;
}

export const TourDestinationsContainer = ({
  tripType,
  isCondensed,
  isVisible,
  onAdd,
  onRemove,
  onClear,
  tabsRef
}: DestinationsContainerProps) => {
  const dispatch = useDispatch();

  const { loading, data: tours, query } = useSelector(getAlgoliaToursByType(tripType));
  const selectedTours = useSelector(getToursByType(tripType));
  const { airports } = useSelector(getSearchParams);

  const handleOnInputChange = (query: string) => {
    if (isVisible) {
      dispatch(setToursQueryByType({ tripType, query }));
    }
  };

  return (
    <TourDestinations
      tabsRef={tabsRef}
      input={query}
      options={tours}
      values={selectedTours}
      isLoading={loading}
      onInputChange={handleOnInputChange}
      onAdd={onAdd}
      onRemove={onRemove}
      onClear={onClear}
      isCondensed={isCondensed}
      tripType={tripType}
      airports={airports}
    />
  );
};
