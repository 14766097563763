import styled from '@emotion/styled';
import React from 'react';
import { Icon } from '@components/common/generic-icon';
import { Icons } from '@model/common/icons';
import { ThemeProps } from '@theme/base';

const Check: any = styled(Icon)({
  fontSize: '1.2rem'
});

interface ContainerProps extends ThemeProps {
  checked: boolean;
  hover: boolean;
  available: boolean;
  disabled?: boolean;
}

const Container: any = styled.div(({ theme, checked, available, disabled }: ContainerProps) => ({
  width: 24,
  height: 24,
  border: `2px solid ${theme.custom.colors.group10.light}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '',
  ...(checked && {
    borderColor: theme.custom.colors.group1.base,
    '> i': {
      color: theme.custom.colors.group1.base
    }
  }),
  ...(!available && {
    borderColor: theme.custom.colors.group10.light,
    '> i': {
      color: theme.custom.colors.group10.light
    }
  }),
  ...(!available &&
    checked && {
      borderColor: theme.custom.colors.group10.light,
      '> i': {
        color: theme.custom.colors.group5.base
      }
    }),
  ...(disabled && {
    backgroundColor: theme.custom.colors.group10.lighter
  })
}));

export interface CheckboxIconProps {
  checked: boolean;
  hover: boolean;
  available: boolean;
  disabled?: boolean;
}

export const CheckboxIcon = ({ checked, hover, available, disabled }: CheckboxIconProps) => {
  return (
    <Container checked={checked} hover={hover} available={available} disabled={!!disabled}>
      {checked && <Check name={Icons.CHECK} />}
    </Container>
  );
};
